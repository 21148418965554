<template>
    <div class="download">
        <bread-nav></bread-nav>
        <div class="search-box">
            <input type="text" v-model="keyword" placeholder="请输入型号或名称" />
            <el-button
                class="search-btn"
                @click="
                    currenPage = 1
                    downLoad()
                "
                >搜索</el-button
            >
        </div>
        <div class="result-table">
            <div v-for="item in  download.slice(0,1)" :key="item.id">
                <el-table :data="download" :header-cell-style="{ background: 'rgb(250,250,250)', color: '#333333' }" style="width: 100%">
                    <el-table-column prop="id" label="序号" width="120" align="center"> </el-table-column>
                    <el-table-column label="商品图片" width="120" align="center">
                        <img :src="'https://jzwy.yfbbc.com//' + item.image" alt="" />
                    </el-table-column>
                    <el-table-column prop="goods_name" label="产品名称" align="center"> </el-table-column>
                    <el-table-column prop="model" label="产品型号" align="center"> </el-table-column>
                    <el-table-column prop="brand" label="品牌" align="center"> </el-table-column>
                    <el-table-column label="超链接" align="center">
                        <template slot-scope="scope">
                          <a :href="scope.row.a" style="color:#65D0F8;text-decoration:underline" target="_blank"  class="buttonText">{{ scope.row.a }}</a>
                        </template>
                    </el-table-column>
                    <el-table-column prop="code" label="提取码" align="center">
                        <template slot-scope="scope">
                            <div class="cart-flex">
                                <span class="code-get">{{ scope.row.code }}</span>
                                <span class="join-cart" v-clipboard:copy="scope.row.code" v-clipboard:success="onCopy" v-clipboard:error="onError"><img src="~img/alibaba/cart-fff.png" /></span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="jump">
            <el-pagination class="jump-count" :page-size="pageSize" @current-change="handlePageChange" :current-page.sync="currentPage" layout="prev, pager, next, jumper" ref="pagination" :total="total"> </el-pagination>
        </div>
    </div>
</template>

<script>
import BreadNav from "@/layout/components/bread-nav"
import { sendMessage, bindServicer, messageList, hasServicers, sessionList, closeMessage, readMessage, currStore, getKeywords, getSiteInfo, downLoad } from "@/api/message"
// import { downLoad } from "@/api/download"

export default {
    name: "download",
    components: { BreadNav },
    data: () => {
        return {
            keyword: "",
            loading: true,
            list: [],
            currentPage: 1,
            total: 0,
            pageSize: 10,
            keywords: "",
            download: []
        }
    },
    created() {
        this.downLoad()
    },
    methods: {
        downLoad() {
            downLoad({
                page: this.currentPage,
                page_size: this.pageSize,
                keyword: this.keyword
            }).then((res) => {
                if (res.code >= 0 && res.data) {
                    // console.log(res.data.list)
                    // console.log(res)
                    this.download = res.data.list

                    console.log(this.download[0].image)
                    this.total = res.data.count

                    // this.shopinfo = res.data
                }
            })
        },
        search() {
            // console.log(this.keyword)
        },
        onCopy() {
            this.$message.success("内容已复制到剪切板！")
        },
        onError(e) {
            this.$message.error("复制失败！")
        },
        handlePageSizeChange(size) {
            this.pageSize = size
            this.refresh()
        },
        handlePageChange() {
            this.downLoad()
        }
    }
}
</script>
<style lang="scss" scoped>
.bread-nav {
    margin-bottom: 30px;
}
.download {
    padding: 30px 0;

    .search-box {
        display: flex;
        align-items: center;
        justify-content: center;

        input {
            border: 1px solid #d9d9d9;
            width: 500px;
            height: 40px;
            box-sizing: border-box;
            padding: 0 12px;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            outline: none;
        }

        .el-button {
            height: 40px;
            width: 70px;
            background-color: $base-color;
            font-size: $ns-font-size-base;
            color: #ffffff;
            text-align: center;
            border-radius: 0px;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
        }
    }

    .result-table {
        margin-top: 30px;

        .cart-flex {
            display: flex;
            align-items: center;
            justify-content: center;

            .code-get {
                width: 60px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $ns-font-size-lg;
                color: $ns-text-color-black;
                border: 1px solid #d9d9d9;
                border-right: 0;
                box-sizing: border-box;
            }

            .join-cart {
                width: 32px;
                height: 32px;
                background-color: $base-color;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 16px;
                    height: 16px;
                }
            }
        }

        .ss-link {
            text-decoration: underline;
            font-size: 15px;
            color: #315efb;
        }
    }

    .el-table {
        .cell {
            font-size: $ns-font-size-lg;
        }
    }
}
</style>
